//@ts-nocheck
import React, { useState, useEffect } from "react";
import Client from "shopify-buy";
import { getLocalState } from "../helper/local-storage";
import { setSessionState } from "../helper/session-storage";
import { CART_PRODUCTS } from "./local-cart-provider";
import awsconfig from '../aws-exports';
import Amplify from 'aws-amplify';

Amplify.configure(awsconfig);

export const CartContext = React.createContext();

const client = Client.buildClient({
  storefrontAccessToken: process.env.GATSBY_SHOPIFY_ACCESS_TOKEN, // Load from env
  domain: `${process.env.GATSBY_SHOP_NAME}.myshopify.com`, // Load from env
});

const CartProvider = ({ children }) => {
  let initialStoreState = {
    client,
    updating: false,
    checkout: { lineItems: [] },
    products: [],
    shop: {},
    customerToken: null,
  };

  const [store, updateStore] = useState(initialStoreState);
  const [initialize, setIntialize] = useState(false);

  useEffect(() => {
    const initializeCheckout = async (token) => {
      const setCheckoutInState = (checkout) => {
        updateStore((prevState) => {
          return { ...prevState, checkout };
        });
      };
      const createNewCheckout = () => store.client.checkout.create();
      const newCheckout = await createNewCheckout();			     
      setCheckoutInState(newCheckout);
    };
    
    const initializeCustomerToken = async () => {

    };    

    initializeCustomerToken();
    //console.log("store.customerToken", store.customerToken);
    initializeCheckout(store.customerToken ? store.customerToken.accessToken : null);
  }, [initialize]);

  return (
    <CartContext.Provider
      value={{
        store,
        addToCartAndCheckout: async (deliveryDate, note) => {
          const { checkout, client , customerToken} = store;
          const checkoutId = checkout.id;
          //console.log("checkout: ", checkoutId)
          updateStore((prevState) => {
            return { ...prevState, updating: true };
          });
          const products = getLocalState(CART_PRODUCTS);
          const lineItemsToUpdate = [];

          products.forEach((product) => {
            lineItemsToUpdate.push({
              variantId: product.variantId,
              quantity: product.quantity,
            });
          });
          let windowReference = window.open("", "_self");
          //note = encodeURI(note)
          //console.log("note b4 co:", note)
          note = note.replace(/[\r\n"]+/g," ");
          note = note.replace(/"/g, "'");
          const input = {customAttributes: [{key: "DeliveryDate", value: deliveryDate}], note};

          return client.checkout
            .addLineItems(checkoutId, lineItemsToUpdate)
            .then(async(checkout) => {
            client.checkout.updateAttributes(checkoutId, input)
            .then(async(checkout) => {
				if (customerToken === null) {
					 const user = await Amplify.Auth.currentUserInfo(); 
					 var tokenJson = null;
					 //console.log("user: ", user);
				 
					 if (user) {
						 const email =  user.attributes.email;  
						 const pass =  user.attributes.sub.substring(0, 13);
						  const query = `mutation {
								  customerAccessTokenCreate(
									  input: {
										email: "${email}",
										password: "${pass}"
									  }
									)
									{
										customerAccessToken {
										  accessToken
										  expiresAt
										}
										customerUserErrors {
										  code
										  field
										  message
										}
									  }            
								}
							`;

							var requestOptions = {
								method: 'POST',
								headers: { 'Content-Type': 'application/graphql',
											'Accept': 'application/json',
											'X-Shopify-Storefront-Access-Token': process.env.GATSBY_SHOPIFY_ACCESS_TOKEN},
								body: query
							};
	
							const response = await fetch('https://farmtotruckthailand.myshopify.com/api/2020-07/graphql', requestOptions );
							tokenJson = await response.json();
							//console.log(tokenJson); 
						}
					
					  const setCustomerTokentInState = (customerToken) => {
						updateStore((prevState) => {
						  return { ...prevState, customerToken };
						});
					  };
					  if (tokenJson) {
						 const accessToken = tokenJson.data.customerAccessTokenCreate.customerAccessToken.accessToken;
						 setCustomerTokentInState(accessToken);
						 const CustomerAssociateQuery = `mutation {
							  checkoutCustomerAssociateV2(
									checkoutId: "${checkoutId}",
									customerAccessToken: "${accessToken}"
								)
								{
									checkout {
									  id
									  webUrl
									}
									checkoutUserErrors {
									  code
									  field
									  message
									}
									customer {
									  id
									}
								  }          
							}
						`;   
						var requestOptions = {
							method: 'POST',
							headers: { 'Content-Type': 'application/graphql',
										'Accept': 'application/json',
										'X-Shopify-Storefront-Access-Token': process.env.GATSBY_SHOPIFY_ACCESS_TOKEN},
							body: CustomerAssociateQuery
						};

						const response = await fetch('https://farmtotruckthailand.myshopify.com/api/2020-07/graphql', requestOptions );
						//console.log("response", await response.json()); 				 	
					  }     
				  } ;          
			
				  //windowReference.location = checkout.webUrl;
				  //window.location.href = checkout.webUrl.replace(`${process.env.GATSBY_SHOP_NAME}.myshopify.com`, process.env.SHOP_DOMAIN_NAME);
				  setSessionState("checkoutId", checkoutId);
				  windowReference.location = checkout.webUrl.replace(`${process.env.GATSBY_SHOP_NAME}.myshopify.com`, process.env.SHOP_DOMAIN_NAME);
				  //console.log(JSON.stringify(checkout));
				  setIntialize(Date.now());
				  updateStore((prevState) => {
					return {
					  ...prevState,
					  checkout,
					  updating: false,
					};
				  });
              });              
            });
        },       
      }}
    >
      {children}
    </CartContext.Provider>
  );
};

export default CartProvider;
