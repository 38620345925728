/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ap-south-1",
    "aws_cognito_identity_pool_id": "ap-south-1:a91abe48-2f3e-4f24-af1a-7aa22ee8f8ae",
    "aws_cognito_region": "ap-south-1",
    "aws_user_pools_id": "ap-south-1_HFOQknZEC",
    "aws_user_pools_web_client_id": "3iq1md17m8s8ka82b417796i8r",
    "oauth": {},
    "aws_cloud_logic_custom": [
        {
            "name": "shop",
            "endpoint": "https://5l05yt9717.execute-api.ap-south-1.amazonaws.com/dev",
            "region": "ap-south-1"
        }
    ]
};


export default awsmobile;
